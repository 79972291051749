<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('duih.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-form>
        <van-row>
          <van-cell :title="$t('duih.out')" center>
            <template #icon>
              <span class="dot_green"></span>
            </template>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="bzvalue" :options="bizhong" />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <van-cell :title="$t('duih.in')" center>
            <template #icon>
              <span class="dot_red"></span>
            </template>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="tovalue" />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <div
            style="
              width: 100%;
              text-align: left;
              padding-left: 13px;
              margin: 12px 0px;
            "
          >
            {{ $t('duih.text') }}待完善
          </div>
          <div
            style="
              width: 100%;
              text-align: left;
              padding-left: 13px;
              margin: 12px 0px;
            "
          >
            {{ $t('duih.fee') }}0
          </div>
          <van-field
            v-model="value"
            :label="$t('duih.number')"
            :placeholder="$t('duih.enter')"
            clearable
            :rules="[{ required: true, message: '该项为必填项' }]"
          >
          </van-field>
        </van-row>
        <van-button class="submitBtns" @click.prevent="submit">{{
          $t('common.submit')
        }}</van-button>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bzvalue: 0,
      bizhong: [{ text: 'USDT', value: 0 }],
      // 兑换数量
      value: '',
      tovalue: 0,
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {},
    submit() {
      if (this.tovalue === 0) {
        this.$toast.fail(this.$t('duih.toast'))
      }
    },
  },
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 65px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .van-cell__title {
    text-align: left;
    font-size: 0.98667rem;
  }
  .van-cell {
    font-size: 0.98667rem;
    background-color: #fff !important;
    height: 3.46667rem;

    &:after {
      border-bottom: 0.02667rem solid #d0d0d1;
    }
    .dot_green {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #04ac92;
      margin-right: 8px;
      display: inline-block;
    }
    .dot_red {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #e10020;
      margin-right: 8px;
      display: inline-block;
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: #212121 !important;
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
  .van-field {
    height: 3.4rem;
    font-size: 0.98667rem;
    /deep/.van-field__label {
      color: #323232;
    }
  }
  .van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
  }
  /deep/.van-field__error-message--right,
  /deep/.van-field__control,
  /deep/.van-field__error-message {
    text-align: right;
  }
  .message {
    margin-top: 0.8rem;
    font-size: 1.06667rem;
    color: #000;
  }
  .submitBtns {
    margin-top: 40%;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 4rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
</style>
